import $ from "jquery";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import LogoV2 from "../../assets/images/logo-v2.png";
import Logo from "../../assets/images/logo.png";

class HomeTwoHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.ScrtiptInit();
  }
// all jquery script 
  ScrtiptInit() {
    $(document).ready(function () {
      // header active class
      $(".main-nav ul li a").click(function () {
        $("li a").removeClass("active");
        $(this).addClass("active");
      });
      $(".main-nav ul li.has-child-menu ul.sub-menu li a").click(function () {
        $(this)
          .parent()
          .parent()
          .parent()
          .find("a.has-child-menu-item")
          .addClass("active");
      });
      // header active class end

      /***** Mobile Menu Js *****/
      $(".hamburger").on("click", function (event) {
        $(this).toggleClass("h-active");
        $(".main-nav").toggleClass("slidenav");
      });

      $(".header-home .main-nav ul li  a").on("click", function (event) {
        $(".hamburger").removeClass("h-active");
        $(".main-nav").removeClass("slidenav");
      });

      $(".main-nav .fl").on("click", function (event) {
        var $fl = $(this);
        $(this).parent().siblings().find(".sub-menu").slideUp();
        $(this)
          .parent()
          .siblings()
          .find(".fl")
          .addClass("flaticon-plus")
          .text("+");
        if ($fl.hasClass("flaticon-plus")) {
          $fl.removeClass("flaticon-plus").addClass("flaticon-minus").text("-");
        } else {
          $fl.removeClass("flaticon-minus").addClass("flaticon-plus").text("+");
        }
        $fl.next(".sub-menu").slideToggle();
      });
           /***** Mobile Menu Js end *****/
      /****** schedule-sidebar JS ******/
      document.querySelectorAll(".sidebar-style-two i").forEach((element) => {
        element.addEventListener("click", () => {
          document
            .querySelectorAll(".schedule-sidebar")
            .forEach((element) => element.classList.add("sb-active"));
        });
      });
      document.querySelectorAll(".sb-toggle-icon").forEach((element) => {
        element.addEventListener("click", () => {
          document
            .querySelectorAll(".schedule-sidebar")
            .forEach((element) => element.classList.remove("sb-active"));
        });
      });
      /****** schedule-sidebar JS  end******/
      /****** custom Cursor  JS  end******/
      var cursor = document.querySelector(".cursor");
      var cursorinner = document.querySelector(".cursor2");
      var a = document.querySelectorAll("a");

      document.addEventListener("mousemove", function (e) {
        cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
      });

      document.addEventListener("mousemove", function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + "px";
        cursorinner.style.top = y + "px";
      });

      document.addEventListener("mousedown", function () {
        cursor.classList.add("click");
        cursorinner.classList.add("cursorinnerhover");
      });

      document.addEventListener("mouseup", function () {
        cursor.classList.remove("click");
        cursorinner.classList.remove("cursorinnerhover");
      });

      a.forEach((item) => {
        item.addEventListener("mouseover", () => {
          cursor.classList.add("hover");
        });
        item.addEventListener("mouseleave", () => {
          cursor.classList.remove("hover");
        });
      });
      // ccustom cursor end
      /****** Sticky Navber Js ******/
      $(window).on("scroll", function () {
        var scroll = $(window).scrollTop();
        if (scroll >= 20) {
          $(".header-area").addClass("sticky");
        } else {
          $(".header-area").removeClass("sticky");
        }
      });
      /****** Sticky Navber Js end ******/
      // preloader
      $(".preloader").delay(100).fadeOut("slow");
      // preloader end
    });
  }

  // // smooth scroll
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {

    return (
      <>
        
        {/* ===============  topbar area start  =============== */}
        <div className="topbar-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 d-lg-block d-none">
                <ul className="topbar-contact-wrap">
                  <li>
                    <Link to={"#"}>
                      <i className="bi bi-geo-alt" /> Btn Andi tonro Kab. Gowa
                    </Link>
                  </li>
                  <li>
                    <a href="tel:6285186828648">
                      <i className="bi bi-telephone-plus" /> (62)
                      0851-8682-8648
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@lariku.id">
                      <i className="bi bi-envelope-open" /> info@lariku.id
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-5">
                <ul
                  className="
                topbar-social-links
                justify-content-lg-end justify-content-center
              "
                >
                  <li>
                    <Link to={"#"}>
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/korprirun/" target="_blank" rel="noreferrer">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <Link to={"#"}>
                      <i className="fab fa-whatsapp" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  topbar area end  =============== */}
        {/* ===============  header style two start =============== */}
        <header>
          <div className="header-area header-style-two">
            <div className="container">
              <div className="row">
                <div
                  className="
                col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12
                d-xl-flex
                align-items-center
              "
                >
                  <div className="logo d-flex align-items-center justify-content-between">
                    <Link to={`${process.env.PUBLIC_URL}/`}>
                      <img src={Logo} alt="logo" />
                    </Link>
                    <div className="mobile-menu d-flex">
                      <Link to={"#"} className="hamburger d-block d-xl-none">
                        <span className="h-top" />
                        <span className="h-middle" />
                        <span className="h-bottom" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-8 col-md-8 col-sm-6 col-xs-6">
                  <nav className="main-nav">
                    <div className="inner-logo d-xl-none">
                      <Link to={"#"}>
                        <img src={LogoV2} alt="Imgs" />
                      </Link>
                    </div>
                    <ul>
                      {/* <li><Link to={`${process.env.PUBLIC_URL}/`} class="active">Home <span>01</span> </Link></li>  */}
                      <li>
                        <Link to={"/"} className="active" onClick={this.scrollTop}>
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`#`}
                          onClick={this.scrollTop}
                        >
                          Tentang
                        </Link>
                      </li>
                    </ul>
                    <div className="inner-contact-wrap d-lg-none">
                      <div className="innter-contact-box">
                        <Link to={"#"}>
                          <i className="bi bi-geo-alt" /> Btn Andi tonro Kab. Gowa
                        </Link>
                      </div>
                      <div className="innter-contact-box">
                        <a href="tel:6285186828648">
                          <i className="bi bi-telephone-plus" /> (62)
                          0851-8682-8648
                        </a>
                      </div>
                      <div className="innter-contact-box">
                        <a href="mailto:info@lariku.id">
                          <i className="bi bi-envelope-open" /> info@lariku.id
                        </a>
                      </div>
                    </div>
                    <div className="inner-btn d-xl-none">
                      <Link
                        to={`#`}
                        onClick={this.scrollTop}
                        className="primary-btn-fill"
                      >
                        Lihat Event
                      </Link>
                    </div>
                  </nav>
                </div>
                <div className="col-xl-3 col-2 d-none d-xl-block">
                  <div
                    className="
                  nav-right
                  h-100
                  d-flex
                  align-items-center
                  justify-content-end
                "
                  >
                    <ul className="d-flex align-items-center nav-right-list">
                      <li className="nav-btn">
                        <Link
                          className="primary-btn-fill"
                          to={`#`}
                        >
                          Lihat Event
                        </Link>
                      </li>
                      <li className="sidebar-style-two">
                        <Link to={"#"}>
                          <i className="bi bi-columns-gap" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* ===============  header style two end =============== */}
        {/* <!-- Custom Cursor --> */}
        <div className="cursor"></div>
        <div className="cursor2"></div>
        {/* Custom Cursor End  */}
      </>
    );
  }
}

export default HomeTwoHeader;
