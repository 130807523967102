import React, { Component } from "react";
import EventArea from "./EventArea";
import HeroArea from "./HeroArea";
import NewsLetter from "./NewsLetter";

class HomePageTwo extends Component {
  render() {
    return (
      <>
        {/* All section componetn import start */}
        <HeroArea />
        <EventArea />
        <NewsLetter />
        {/* All section componetn import end  */}
      </>
    );
  }
}

export default HomePageTwo;
