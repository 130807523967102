import React, { Component } from "react";
import { Link } from "react-router-dom";
// image import
import LogoV2 from "../../assets/images/logo-v2.png";
class HomeTwoFooter extends Component {
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <>
        {/* ===============  footer area start  =============== */}
        <div className="footer-outer ">
          <div className="footer-area position-relative">
            <div className="container position-relative">
              <div className="footer-wrapper">

                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-5 order-1">
                    <div className="footer-widget mt-0">
                      <h5 className="footer-widget-title">Quick Link</h5>
                      <div className="footer-links">
                        <ul className="link-list">
                          <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/tentang`}
                              className="footer-link"
                            >
                              Tentang
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`#`}
                              className="footer-link"
                            >
                              Event
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-2 order-3">
                    <div className="footer-about">
                      <div className="footer-logo">
                        <img src={LogoV2} alt="logo v2" />
                      </div>
                      <p>
                        lariku.id adalah event organizer untuk event fun run dan marathon
                      </p>
                      <ul className="footer-social-icon d-flex">
                        <li>
                        <a href="https://www.instagram.com/korprirun/" target="_blank" rel="noreferrer">
                            <i className="fab fa-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="https://wa.me/+6285186828648?text=Halo%20Admin%20Lariku" target="_blank" rel="noreferrer"><i className="fab fa-whatsapp"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-7 order-lg-3 order-2 ">
                    <div className="footer-widget">
                      <h5 className="footer-widget-title">Contact</h5>
                      <div className="footer-links">
                        <ul className="link-list">
                          <li className="contact-box">
                            <div className="contact-icon">
                              <i className="bi bi-telephone-plus" />
                            </div>
                            <div className="contact-links">
                              <Link
                                onClick={this.scrollTop}
                                to={"tel:6285186828648"}
                              >
                                +62 0851-8682-8648
                              </Link>
                            </div>
                          </li>
                          <li className="contact-box">
                            <div className="contact-icon">
                              <i className="bi bi-envelope-open" />
                            </div>
                            <div className="contact-links">
                              <Link
                                onClick={this.scrollTop}
                                to={"mailto:info@lariku.id"}
                              >
                                info@lariku.id
                              </Link>
                            </div>
                          </li>
                          <li className="contact-box">
                            <div className="contact-icon">
                              <i className="bi bi-geo-alt" />
                            </div>
                            <div className="contact-links">
                              <Link onClick={this.scrollTop} to={"#"}>
                              Btn Andi Tonro Kab. Gowa
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-bottom footer-bottom-style-two">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="footer-copyright text-center">
                      <p>
                        Copyright {new Date().getFullYear()} lariku.id | Development by 
                        <a href='https://imtoor.com' target='_blank' rel='noreferrer'>
                          &nbsp;<u>imtoor</u>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  footer area end  =============== */}.
      </>
    );
  }
}

export default HomeTwoFooter;
