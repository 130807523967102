import React, { Component } from "react";
import { Link } from "react-router-dom";
// image import
import BGtestIMG from "../../../assets/images/background-texts/event-bg2.png";
import EventIMG2 from "../../../assets/images/event/ev-md14.png";
class EventArea extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      onGoingItems: [
        {
         img: 'https://ik.imagekit.io/vgmb9coa9/lariku/events/korpri-run-2024/item.png?updatedAt=1719275416197',
         date: 'Oktober 2024',
         quota: 100,
         location: 'Makassar, Sulawesi Selatan',
         title: 'Korpri Run 2024',
         detailLink: `#`,
         registerButton: true,
        },
      ],
      pastEventItems: [
        {
         img: 'https://ik.imagekit.io/vgmb9coa9/lariku/events/gowa-run-2024/tr:w-370,h-230/item-cropped.jpg?updatedAt=1719273265493',
         date: '14 Januari 2024',
         quota: 2500,
         location: 'Gowa, Sulawesi Selatan',
         title: 'Gowa Run 2024',
         detailLink: `#`,
         registerButton: false,
        },
        {
          img: 'https://ik.imagekit.io/vgmb9coa9/lariku/events/arebi-ciputra-funrun-2024/tr:w-370,h-230/item?updatedAt=1719272176442',
          date: '5 Mei 2024',
          quota: 1500,
          location: 'Makassar, Sulawesi Selatan',
          title: 'Arebi Ciputra Fun Run 2024',
          detailLink: `#`,
          registerButton: false,
         },        
      ]      
    }
    
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  onGoingCom = (props) => {
    const item = props.items;
    return (
      item.length > 0 && item.map(val => (
        <div className="col-lg-4 col-md-6">
          <div className="event-card-style-two">
            <div className="event-thumb">
              <img src={val.img} alt="EventIMG" />
              <div className="event-date">
                <Link onClick={this.scrollTop} to={"#"}>
                  <i className="bi bi-calendar2-week" /> {val.date}
                </Link>
              </div>
            </div>
            <div className="event-content">
              <div className="event-seat-lavbel">
                <h6>
                  <i className="bi bi-diagram-3" /> {val.quota}
                </h6>
              </div>
              <div className="event-card-main">
                <div className="event-location">
                  <Link onClick={this.scrollTop} to={"#"}>
                    <i className="bi bi-geo-alt" /> {val.location}
                  </Link>
                </div>
                <h5 className="event-title">
                  <Link onClick={this.scrollTop} to={"#"}>
                    {val.title}
                  </Link>
                </h5>
                <div className="event-readme">
                  <Link
                    onClick={this.scrollTop}
                    to={val.detailLink}
                  >
                    {val.registerButton && 'REGISTER'}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))
    )
  }

  render() {
    return (
      <>
        {/* ===============  Event area start  =============== */}
        <div className="event-style-two pt-110 position-relative">
          <div className="watermark-bg mt-110">
            <img src={BGtestIMG} alt="BG testIMG" className="img-fluid" />
          </div>
          <div className="container">
            <div className="row">
              <div className="section-head-style-two">
                <h3>
                  Semua <span>Event</span>
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="event-category-buttons ">
                  <ul
                    className="nav nav-pills mb-3"
                    id="events-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-tab1"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-event1"
                        type="button"
                        role="tab"
                        aria-controls="pills-event1"
                        aria-selected="true"
                      >
                        Sedang Berlangsung
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-tab2"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-event2"
                        type="button"
                        role="tab"
                        aria-controls="pills-event2"
                        aria-selected="false"
                      >
                        Akan Datang
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-tab3"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-event3"
                        type="button"
                        role="tab"
                        aria-controls="pills-event3"
                        aria-selected="false"
                      >
                        Sudah Selesai
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="all-event-cards">
                  <div className="tab-content" id="events-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-event1"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      <div className="row">
                        <this.onGoingCom items={this.state.onGoingItems} />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-event2"
                      role="tabpanel"
                      aria-labelledby="pills-tab2"
                    >
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="event-card-style-two">
                            <div className="event-thumb">
                              <img src={`https://earlsribpalace.com/wp-content/uploads/2019/07/coming-soon-store-placeholder-image.gif`} alt="EventIMG" />
                              <div className="event-date">
                                <Link onClick={this.scrollTop} to={"#"}>
                                  <i className="bi bi-calendar2-week" /> 
                                </Link>
                              </div>
                              <div className="event-date">
                                <Link onClick={this.scrollTop} to={"#"}>
                                  <i className="bi bi-calendar2-week" /> 
                                </Link>
                              </div>
                            </div>
                            <div className="event-content">
                              <div className="event-seat-lavbel">
                                <h6>
                                  <i className="bi bi-diagram-3" />
                                </h6>
                              </div>
                              <div className="event-card-main">
                                <div className="event-location">
                                  <Link onClick={this.scrollTop} to={"#"}>
                                    <i className="bi bi-geo-alt" />
                                  </Link>
                                </div>
                                <h5 className="event-title">
                                  <Link onClick={this.scrollTop} to={"#"}>
                                    Coming soon...
                                  </Link>
                                </h5>
                                <div className="event-readme">
                                  <Link
                                    onClick={this.scrollTop}
                                    to={`#`}
                                  >
                                    
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-event3"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      <div className="row">
                        <this.onGoingCom items={this.state.pastEventItems} />
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  Event area end  =============== */}
      </>
    );
  }
}

export default EventArea;
